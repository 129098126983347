import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/auth_successful": [~5],
		"/compare": [6],
		"/detail/[fund_id=sfid]/[[share_class_id=sfid]]/experience": [8,[2]],
		"/detail/[fund_id=sfid]/[[share_class_id=sfid]]/experience/show": [9,[2]],
		"/detail/[fund_id=sfid]/[[share_class_id=sfid]]": [~7],
		"/fund-experience/old": [10],
		"/fund-structure-v2": [11],
		"/portfolio": [12],
		"/search": [13],
		"/[lang=lang]/[products=products]/[isin=isin]": [~4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';